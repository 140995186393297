body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#reg-form-container {
  display: flex;
}

#reg-form {
  margin: auto;
}

#reg-form-logo img {
  width: 400px;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
}

.dashboard-header-img img {
  width: 18rem;
}

.dashboard-header-merch-id {
  margin: auto 0;
  font-weight: bold;
}

.tracking-switches {
  margin: 2rem 1rem;
}

.tracking-switch {
  margin-bottom: 2.5rem;
}

.tracking-switch label {
  display: flex;
}

.tracking-switch-text {
  margin-left: 1.5rem;
}

.settings-tracker {
  display: flex;
  justify-content: space-between;
}

.settings-tracker-form-group {
  display: flex;
  gap: 1rem;
}

.settings-tracker-form {
  flex: 1;
  padding: 1rem 2rem 1rem 0;
  border-right: 1px solid #ececed;
  margin-right: 2rem;
}

.settings-tracker-text {
  width: 13rem;
  margin: 1rem;
}

hr {
  border: 1px solid #ececed;
}

.settings-tracker-btn-submit-wrap {
  float: right;
}

.status-panel {
  display: flex;
  justify-content: space-between;
}

.status-container {
  padding-top: 0;
  box-shadow: none;
}

.status-panel-table {
  flex: 1;
  padding: 1rem 2rem 1rem 0;
  border-right: 1px solid #ececed;
  margin-right: 2rem;
}

.status-panel-text {
  width: 13rem;
  margin: 1rem;
}

.help-submit-btn-wrap {
  display: flex;
  justify-content: flex-end;
  max-width: 26rem;
}

.loader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  align-items: center;
  background-color: rgba(49, 52, 64, 0.5);
  z-index: 999;
}

#instructions-sidebar {
  background-color: #ececec;
  display: flex;
  width: 30%;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 5px;
  border: 2px solid #DEDEDE;
  border-radius: 7px;
  padding: 5px;
  text-align: center;
}

#instructions-sidebar h2 {
  color: #88161b;
  text-transform: uppercase;
  font-family: commuters-sans, sans-serif;
  font-size: 14pt;
  margin-top: 7px;
}

#instructions-sidebar .instruction-text {
  font-size: 10pt;
  margin: 6px 0 6px;
}

#instructions-sidebar .small {
  font-size: 9pt;
  color: #666;
}

.sync-btn {
  margin-bottom: 0.25rem;
  font-size: 14px;
  height: auto;
}

.sync-alert {
  animation: 1s fadeOut;
  visibility: visible;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}

@media screen and (max-width: 720px) {
  .status-panel {
    flex-wrap: wrap;
  }

  .status-panel-text {
    width: 100%;
    margin: 0;
  }
}
